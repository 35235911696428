import React from "react";
import "./banner.css";

function Banner(){
    return (
    <>
<section class="header">
    <h1>Available on all App Store</h1>
    <p>Download now & enjoy app with unimited features</p>
    <a class="btn-bgstroke">Download and Install</a>
</section>
    </>
)
}
export default Banner;